@import url('https://fonts.googleapis.com/css?family=Bad+Script|Open+Sans:400,600');
html {
  background-color: #e6f5f3;
  background: linear-gradient(90deg, #e0d7f5, #e6f5f3);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  padding: 0 1rem 1rem;
  position: relative;
}
html:before {
  mask-image: linear-gradient(to bottom, transparent, black);
  background: linear-gradient(90deg, #d5f3ef, #fbcfe9);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
body {
  margin: 0 auto;
  max-width: 1024px;
  min-height: 100vh;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
h1, h2, h3 {
  font-weight: 400;
}
h4, h5, h6 {
  font-weight: 600;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
p {
  margin: 1rem 0;
}
.centered {
  text-align: center;
}
.spacing {
  margin: .5rem 0;
}
.spacing-top {
  margin-top: .5rem;
}
.spacing-bottom {
  margin-bottom: .5rem;
}
.divider {
  border-left: 1px solid black;
  display: inline-block;
  height: 1.4rem;
  margin: 0 1rem;
}
@import url(https://fonts.googleapis.com/css?family=Bad+Script|Open+Sans:400,600);
html {
  background-color: #e6f5f3;
  background: -webkit-gradient(linear, left top, right top, from(#e0d7f5), to(#e6f5f3));
  background: linear-gradient(90deg, #e0d7f5, #e6f5f3);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  padding: 0 1rem 1rem;
  position: relative;
}
html:before {
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(black));
  -webkit-mask-image: linear-gradient(to bottom, transparent, black);
          mask-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(black));
          mask-image: linear-gradient(to bottom, transparent, black);
  background: -webkit-gradient(linear, left top, right top, from(#d5f3ef), to(#fbcfe9));
  background: linear-gradient(90deg, #d5f3ef, #fbcfe9);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
body {
  margin: 0 auto;
  max-width: 1024px;
  min-height: 100vh;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
h1, h2, h3 {
  font-weight: 400;
}
h4, h5, h6 {
  font-weight: 600;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
p {
  margin: 1rem 0;
}
.centered {
  text-align: center;
}
.spacing {
  margin: .5rem 0;
}
.spacing-top {
  margin-top: .5rem;
}
.spacing-bottom {
  margin-bottom: .5rem;
}
.divider {
  border-left: 1px solid black;
  display: inline-block;
  height: 1.4rem;
  margin: 0 1rem;
}
section {
    padding: 1rem;
}
.flex {
    display: flex;
    align-items: center;
}
.script {
    font-family: 'Bad Script', sans-serif;
}
.Social-Nav {
    justify-content: flex-end;
    flex: 100% 1;
    position: absolute;
    right: 1rem;
    top: 1rem;
}
.Social-Item, .Button {
    border: 2px solid black;
    border-radius: 50%;
    color: black;
    margin: 0 3px;
    padding: 5px;
    -webkit-transition: .3s;
    transition: .3s
}
.Social-Item:hover, .Button:hover:not(.active) {
    cursor: pointer;
}
.Social-Item:hover, .Button:hover:not(.active), .Button.active {
    background: rgba(70, 38, 146, .5);
    border-color: transparent;
    color: white !important;
}
.Social-Item:hover .Social-Icon {
    -webkit-filter: invert(1);
            filter: invert(1);
}
.Button {
    border-radius: 5px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-left: .5rem;
    text-decoration: none;
}
.Social-Icon {
    background-position: .25px center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    display: block;
    height: 1.2rem;
    -webkit-transition: .3s;
    transition: .3s;
    width: 1.2rem;
}
.Me {
    border: 2px solid black;
    border-radius: 50%;
    display: block;
    height: 10rem;
    margin: 4rem auto 2rem;
    padding: 5px;
    position: relative;
    width: 10rem;
}
.Me img {
    border-radius: 50%;
    display: block;
    opacity: 0;
    position: absolute;
    top: 5px;
    left: 5px;
    -webkit-transition: .7s;
    transition: .7s;
    max-width: 10rem;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.Me img.Active {
    opacity: 1;
}
.desc {
    padding: 2.5rem 0 0;
}
.desc:not(.show) {
    display: none;
}
.Skill-Section {
    margin-top: 16px;
}
.skills {
    list-style-type: none;
    margin: 0 0 0 -4px;
    padding: 0;
}
.skills li {
    display: inline-block;
    margin: 8px 4px;
}
